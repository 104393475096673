<template>
    <modal
        class="image-lightbox"
        :adaptive="true"
        :scrollable="true"
        name="image-lightbox"
        width="1000"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('image-lightbox')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="image-lightbox-container">
            <img class="img-fluid" :src="imageUrl" alt="Full Image">
        </div>
    </modal>
</template>

<script>
export default {
    name: "ImageLightbox",
    data() {
        return {
            imageUrl: ""
        }
    },
    methods: {
        beforeOpen(event) {
            if (event.params?.imageUrl) {
                this.imageUrl = event.params.imageUrl;
            } else {
                event.cancel();
            }
        },
        beforeClose() {
            this.imageUrl = "";
        }
    }
}
</script>

<style lang="scss" scoped>
.image-lightbox {
    padding: 0;

    /deep/ .vm--modal {
        background-color: #1C1C1E;
        border-radius: 3px;
        padding-top: 0;
        box-shadow: none;
        overflow: visible;

        .close-modal {
            position: absolute;
            right: -35px;
            top: 0px;
            cursor: pointer;
            z-index: 3;

            @media(max-width: $sm) {
                right: 5px;
                top: -30px;

                img {
                    width: 15px;
                }
            }
        }
    }
}
</style>
